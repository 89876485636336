$color-white: #fff;
$color-black: #000;
$color-primary: #36a86b;
$color-secondary: #367597;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$color-primary-1: #86d8ac;
$color-primary-2: #56be86;
$color-primary-3: #199c56;
$color-primary-4: #0b793e;
$color-secondary-1-0: #367597;
$color-secondary-1-1: #84b4ce;
$color-secondary-1-2: #548ead;
$color-secondary-1-3: #1d658c;
$color-secondary-1-4: #0f4c6c;
$color-secondary-2-0: #eea44c;
$color-secondary-2-1: #ffd39f;
$color-secondary-2-2: #ffc074;
$color-secondary-2-3: #dc8823;
$color-secondary-2-4: #ab6410;
$color-complement-0: #ee714c;
$color-complement-1: #ffb49f;
$color-complement-2: #ff9374;
$color-complement-3: #dc4d23;
$color-complement-4: #ab3310;
$rgba-primary-0: rgba(54, 168, 107, 1);
$rgba-primary-1: rgba(134, 216, 172, 1);
$rgba-primary-2: rgba(86, 190, 134, 1);
$rgba-primary-3: rgba(25, 156, 86, 1);
$rgba-primary-4: rgba(11, 121, 62, 1);
$rgba-secondary-1-0: rgba(54, 117, 151, 1);
$rgba-secondary-1-1: rgba(132, 180, 206, 1);
$rgba-secondary-1-2: rgba(84, 142, 173, 1);
$rgba-secondary-1-3: rgba(29, 101, 140, 1);
$rgba-secondary-1-4: rgba(15, 76, 108, 1);
$rgba-secondary-2-0: rgba(238, 164, 76, 1);
$rgba-secondary-2-1: rgba(255, 211, 159, 1);
$rgba-secondary-2-2: rgba(255, 192, 116, 1);
$rgba-secondary-2-3: rgba(220, 136, 35, 1);
$rgba-secondary-2-4: rgba(171, 100, 16, 1);
$rgba-complement-0: rgba(238, 113, 76, 1);
$rgba-complement-1: rgba(255, 180, 159, 1);
$rgba-complement-2: rgba(255, 147, 116, 1);
$rgba-complement-3: rgba(220, 77, 35, 1);
$rgba-complement-4: rgba(171, 51, 16, 1);

@function colorSecondary($opacity) {
  @return rgba(54, 117, 151, $opacity);
}

:root {
  --color-primary: $color-primary;
  --color-primary-1: #86d8ac;
  --color-primary-2: #56be86;
  --color-primary-3: #199c56;
  --color-primary-4: #0b793e;
  --color-secondary: $color-secondary;
  --color-secondary-1-1: #84b4ce;
  --color-secondary-1-2: #548ead;
  --color-secondary-1-3: #1d658c;
  --color-secondary-1-4: #0f4c6c;
  --color-secondary-2-0: #eea44c;
  --color-secondary-2-1: #ffd39f;
  --color-secondary-2-2: #ffc074;
  --color-secondary-2-3: #dc8823;
  --color-secondary-2-4: #ab6410;
  --color-complement-0: #ee714c;
  --color-complement-1: #ffb49f;
  --color-complement-2: #ff9374;
  --color-complement-3: #dc4d23;
  --color-complement-4: #ab3310;
  --rgba-primary-0: rgba(54, 168, 107, 1);
  --rgba-primary-1: rgba(134, 216, 172, 1);
  --rgba-primary-2: rgba(86, 190, 134, 1);
  --rgba-primary-3: rgba(25, 156, 86, 1);
  --rgba-primary-4: rgba(11, 121, 62, 1);
  --rgba-secondary-1-0: rgba(54, 117, 151, 1);
  --rgba-secondary-1-1: rgba(132, 180, 206, 1);
  --rgba-secondary-1-2: rgba(84, 142, 173, 1);
  --rgba-secondary-1-3: rgba(29, 101, 140, 1);
  --rgba-secondary-1-4: rgba(15, 76, 108, 1);
  --rgba-secondary-2-0: rgba(238, 164, 76, 1);
  --rgba-secondary-2-1: rgba(255, 211, 159, 1);
  --rgba-secondary-2-2: rgba(255, 192, 116, 1);
  --rgba-secondary-2-3: rgba(220, 136, 35, 1);
  --rgba-secondary-2-4: rgba(171, 100, 16, 1);
  --rgba-complement-0: rgba(238, 113, 76, 1);
  --rgba-complement-1: rgba(255, 180, 159, 1);
  --rgba-complement-2: rgba(255, 147, 116, 1);
  --rgba-complement-3: rgba(220, 77, 35, 1);
  --rgba-complement-4: rgba(171, 51, 16, 1);
}

@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;700&family=Lora:ital@1&family=Rasa:wght@700&display=swap");

// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: 0.4rem;
$success: $color-primary;
$primary: $color-primary;
$secondary: $color-secondary;

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
// @import "bootstrap/scss/print";

//
// Custom styles
//

@import "icon-list";

// START TOP LEVEL STYLES
html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  overflow-x: hidden;
}
*,
p,
span {
  font-family: "Hind Madurai", sans-serif;
}
p {
  margin: 1rem 0;
}
h1,
h2 {
  font-family: "Rasa", serif;
  line-height: 1em !important;
}
h3,
h4 {
  font-family: "Lora", serif;
}
.bold {
  font-weight: 700 !important;
}
.shadow-box {
  transition: box-shadow 0.3s linear;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.03);
}
.rounded-pill {
  border-radius: 50rem !important;
}
.bg-contrast {
  background-color: #fff !important;
}
.bi {
  fill: currentcolor;
}
.bi-arrow-right {
  top: -3px;
  position: relative;
}
.tilt {
  will-change: transform;
  transform: perspective(300px) rotateX(0deg) rotateY(0deg);
}
.js-tilt-glare {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  .js-tilt-glare-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 100%
    );
    width: 1080px;
    height: 1080px;
    transform: rotate(180deg) translate(-50%, -50%);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

a,
a:active,
a:focus,
a:hover {
  outline: 0 !important;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.border-bottom {
  border-bottom: 1px solid #edf4f8 !important;
}
.shadow-hover {
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.085) !important;
  }
}
.text-muted {
  color: #6c757d !important;
  font-weight: 300 !important;
}
.lead {
  font-size: 1.25rem;
}
.btn {
  transition: 0.2s;
  border: none;
  background: $color-secondary-1-3 !important;
  color: $color-white;
  &:hover {
    color: $color-white;
    background-color: $color-secondary-1-3 !important;
  }
}
.swiper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  padding-bottom: 45px !important;
  .swiper-wrapper {
    align-items: center !important;
    .swiper-slide {
      justify-content: center;
      display: flex;
      img {
        width: 100%;
        filter: grayscale(1);
      }
    }
  }
}
.form-control {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.btn,
.form-control {
  border-radius: 0.25rem;
}
.btn-outline-alternate {
  border: 1px solid $color-secondary;
  background: transparent !important;
  color: #367597;
}
.iphone {
  background: #fff;
  max-width: 255px;
  border-radius: 36px;
  padding: 55px 0;
  box-shadow: none;
  border: 1px solid #edf4f8;
  margin: 0 auto;
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, 0.5),
    0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, 0.5);
  &:before {
    background: #e3e8eb;
    margin: -25px auto 0;
    width: 45px;
    height: 4px;
    left: 0;
    right: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    content: "";
    position: absolute;
  }
  &:after {
    top: 30px;
    margin-left: -45px;
    background: #e3e8eb;
    left: 50%;
    width: 8px;
    height: 8px;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.03);
    display: block;
    border-radius: 50%;
    content: "";
    position: absolute;
  }
  .screen {
    width: 90%;
    margin: 0 auto;
    transition: -webkit-box-shadow 0.3s linear;
    transition: box-shadow 0.3s linear;
    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }
}
.css-mb {
  max-width: 650px; /* Set the desired maximum width of the macbook */
  min-width: 50px; /* Set the desired minimum width of the macbook */
  margin: 50px auto 0; /* Align mockup to center */
}

.css-mb div {
  box-sizing: border-box !important; /* Just in case */
}

/* Center the display */
.css-mb .mb-display-position {
  width: 80%;
  margin: 0 auto;
}

/* The display */
.css-mb .mb-display {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 65.9442%;
  background: #373435;

  -webkit-border-top-left-radius: 3.5% 5.3075%;
  -moz-border-radius-topleft: 3.5% 5.3075%;
  border-top-left-radius: 3.5% 5.3075%;

  -webkit-border-top-right-radius: 3.5% 5.3075%;
  -moz-border-radius-toptight: 3.5% 5.3075%;
  border-top-right-radius: 3.5% 5.3075%;
}

/* Webcam */
/* Browser may not render a perfect circle */
.css-mb .mb-display:before {
  content: "";
  display: block;
  position: absolute;
  top: 3%;
  left: 50%;
  width: 1%;
  height: 1.5164%;
  margin-left: -0.5%;
  border-radius: 50%;
  background: #525252;
}

/* Glare */
/* Browser may not render the top and right offset evenly */
.css-mb .mb-display:after {
  content: "";
  display: none;
  position: absolute;
  right: 0.4%;
  top: 0.64%;
  width: 62.5%;
  height: 100%;

  background: none; /* Hide the gradient on older browsers */
  background: -moz-linear-gradient(
    55deg,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 0.05) 61%
  ); /* FF3.6+ */
  background: -webkit-linear-gradient(
    36deg,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 0.05) 61%
  ); /* Chrome10+ and Safari5.1+ compute the degree differently */
  background: -o-linear-gradient(
    55deg,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 0.05) 61%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    55deg,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 0.05) 61%
  ); /* IE10+ */
  background: linear-gradient(
    55deg,
    rgba(0, 0, 0, 0) 61%,
    rgba(255, 255, 255, 0.05) 61%
  );

  -webkit-border-top-right-radius: 5.3075%;
  -moz-border-radius-toptight: 5.3075%;
  border-top-right-radius: 5.3075%;
}

/* Only show glare, if the class is applied */
.css-mb.with-glare .mb-display:after {
  display: block;
}

/* Position the screen and give make it the right size, ratio 16:10 */
.css-mb .mb-screen-position {
  position: absolute;
  top: 6.5%;
  width: 93.2%;
  left: 3.4%;
  height: 0;
  margin: 0;
  padding-bottom: 58.25%; /* Ratio */
}

/* Give parent (this element) a "height", so that child elements can use height: 100%;*/
.css-mb .mb-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #4b4b4d; /* bg color if content is not big enough, or rendering is off */
}

/* Image on the screen */
.css-mb .mb-screen img {
  max-width: 100%;
  height: auto;
}

/* Iframe on the screen */
.css-mb .mb-screen iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/* Macbook body */
.css-mb .mb-body {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 2.3%;
  background: #e6e7e8;
}

/* The groove */
.css-mb .mb-body:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 14.074%;
  height: 0;
  padding-bottom: 1.4814%;
  margin-left: -7.037%;
  background: #a9abae;

  -webkit-border-bottom-left-radius: 7.037% 50%;
  -moz-border-radius-bottomleft: 7.037% 50%;
  border-bottom-left-radius: 7.037% 50%;

  -webkit-border-bottom-right-radius: 7.037% 50%;
  -moz-border-radius-bottomtight: 7.037% 50%;
  border-bottom-right-radius: 7.037% 50%;
}

/* Macbook bottom */
.css-mb .mb-bottom-cover {
  width: 100%;
  height: 0;
  padding-bottom: 0.7407%;
  background: #a9abae;

  -webkit-border-bottom-left-radius: 12% 600%;
  -moz-border-radius-bottomleft: 12% 600%;
  border-bottom-left-radius: 12% 600%;

  -webkit-border-bottom-right-radius: 12% 600%;
  -moz-border-radius-bottomtight: 12% 600%;
  border-bottom-right-radius: 12% 600%;
}
.animation--clockwise {
  animation: clockwise 30s linear infinite normal;
}
.animation--anti-clockwise {
  animation: anti-clockwise 30s linear infinite normal;
}
.animation--rotating {
  animation: rotating 15s linear infinite normal;
}
.animation--rotating-diagonal {
  animation: rotating-diagonal 15s linear infinite alternate;
}
// END TOP LEVEL STYLES

// START SECTION SCOPED STYLES
#main-nav {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  z-index: 1030;
  background-color: white;
  height: 90px;
  transition: all 0.3s ease 0s;
  &.sticky {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
    .navigation-link {
      color: $color-black !important;
    }
  }
  .navbar-toggler {
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    align-self: center;
    margin: 0;
    .icon-bar {
      &:not(:first-of-type) {
        margin-top: 6px;
      }
      transform: rotate(0);
      background-color: $color-primary;
      transition: all 0.35s ease-in-out;
      display: block;
      width: 30px;
      height: 2px;
    }
  }
  &.expanded .navbar-brand {
    transform: translateX(250px);
  }
  &.expanded .navbar-toggler {
    transform: translateX(250px);
    .icon-bar:nth-child(1) {
      transform: rotate(-225deg);
    }
    .icon-bar:nth-child(2) {
      transform: translateX(-40px);
      opacity: 0;
    }
    .icon-bar:nth-child(3) {
      transform: translateY(-15px) rotate(225deg);
    }
  }
  &.expanded .navbar-collapsable {
    left: 0;
  }
  .navbar-collapsable {
    width: 250px;
    left: -250px;
    border-right: 1px solid #edf4f8;
    height: 100vh;
    transition: left 0.4s ease 0s;
    background-color: #fff;
    margin: 0;
    top: 0;
    bottom: 0;
    display: block;
    position: fixed;
  }
  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 55px !important;
  }

  .container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .sidebar-brand {
    padding: 1rem;
  }

  ul.navigation-list {
    background-color: transparent;
    min-height: 90px;
    transition: all 0.3s ease 0s;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    position: relative;
    flex-direction: column;
    margin-left: 0;
    padding-left: 0;
    .navigation-item {
      list-style: none;
      display: flex;
      align-items: center;
    }
    .navigation-link {
      color: $color-black;
      font-family: Lato, sans-serif;
      font-size: 0.9rem;
      font-weight: 700;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
      text-decoration: none;
      letter-spacing: 1px;
      padding: 1rem;
    }
  }
}
#hero-wrapper {
  padding-top: 90px;
  position: relative;
}
.hero {
  padding-top: 90px;
  padding-bottom: 90px;
  h1 {
    font-size: 4rem !important;
    margin-top: 1rem !important;
  }
  h2 {
    font-family: "Lora", serif;
    font-size: 1.25rem;
    margin: 1rem 0;
  }
  p {
    font-size: 1.25rem;
    margin: 1rem 0;
  }
}
.hero-shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  overflow: hidden;
  .shape-animated,
  .shape-in-motion,
  .static-shape {
    opacity: 0.2 !important;
  }
  .shape-animated {
    position: absolute;
    &:nth-child(1) {
      left: 0;
      top: 0;
      width: 600px;
      height: 40px;
      &:before {
        background-image: linear-gradient(
          45deg,
          $color-secondary-1-3 0,
          $color-secondary-1-0 35%,
          $color-secondary-1-2 110%
        );
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(2) {
      top: 20%;
      left: 12%;
      width: 200px;
      height: 10px;
      &:before {
        background-image: linear-gradient(
          90deg,
          $color-primary 0,
          $color-primary-1 100%
        );
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(3) {
      top: 80%;
      left: 45%;
      width: 200px;
      height: 5px;
      &:before {
        background-image: linear-gradient(90deg, #d3d4d5 0, #edf4f8 100%);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(4) {
      top: 82%;
      left: 45%;
      width: 150px;
      height: 15px;
      &:before {
        background-image: linear-gradient(90deg, #d3d4d5 0, #edf4f8 100%);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(5) {
      top: 20%;
      left: 0;
      width: 200px;
      height: 20px;
      &:before {
        background-image: linear-gradient(90deg, #edf4f8 0, #d3d4d5 100%);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(6) {
      top: 34%;
      left: 5%;
      width: 60px;
      height: 6px;
      &:before {
        background-image: linear-gradient(
          90deg,
          $color-primary-1 0,
          $color-secondary 100%
        );
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(7) {
      top: 17%;
      left: 3%;
      width: 100px;
      height: 6px;
      &:before {
        background-image: linear-gradient(90deg, #d3d4d5 0, #d3d4d5 100%);
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(8) {
      top: 70%;
      left: -5%;
      width: 300px;
      height: 5px;
      &:before {
        background-image: linear-gradient(
          90deg,
          $color-primary-4 0,
          $color-secondary 100%
        );
        opacity: 0.5;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
    &:nth-child(9) {
      top: 72%;
      left: -5%;
      width: 200px;
      height: 15px;
      &:before {
        background-color: $color-secondary;
        opacity: 0.5;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: rotate(55deg);
        position: absolute;
        border-radius: 50px;
      }
    }
  }
  .static-shape {
    position: absolute;
    &.background-shape {
      width: 100%;
      height: 100%;
      transform: translate(85%, -30%) rotate(-35deg);
      bottom: 0;
      background-image: linear-gradient(
        45deg,
        $color-primary-4 0,
        $color-primary-2 35%,
        $color-primary-1 110%
      );
      background-color: $color-primary-2;
      display: block;
      border-radius: 120px;
      z-index: -1;
    }
    &.ring-1 {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      border: 15px solid $color-secondary;
      top: 35%;
      left: 0;
      transform: translateX(-50%);
    }
    &.ring-2 {
      border-radius: 50%;
      height: 120px;
      width: 120px;
      border: 15px solid rgba(255, 255, 255, 0.5);
      top: 20%;
      right: 0;
      transform: translateX(50%);
    }
    &.dots-pattern {
      top: 0;
      left: 0;
      width: 150px;
      height: 20%;
      background-color: transparent;
      background-image: radial-gradient(
        rgba(0, 0, 0, 0.1) 3px,
        transparent 3px
      );
      background-size: 30px 30px;
    }
    &.circle-1 {
      transform: translate3d(25%, -25%, 0);
      top: 0;
      right: 0;
      opacity: 0.2;
      div {
        width: 500px;
        height: 500px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 0;
      }
    }
    &.circle-2 {
      transform: translate3d(25%, -25%, 0);
      top: 0;
      right: 0;
      opacity: 0.2;
      div {
        width: 300px;
        height: 300px;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 50%;
        margin: 0;
      }
    }
  }
  .shape-in-motion {
    position: absolute;
    display: flex;
    &.shape-siren {
      top: 35%;
      left: 15%;
      animation-duration: 15s;
      div {
        opacity: 0.5;
        position: relative;
        width: 30px;
        height: 25px;
        &:before {
          position: absolute;
          content: "🚨";
          top: 0;
          left: 15px;
          width: 15px;
          height: 25px;
        }
        &:after {
          //transform: rotate(45deg);
          //transform-origin: 100% 100%;
          //position: absolute;
          //content: "";
          //top: 0;
          //width: 15px;
          //height: 25px;
          //background: #007cb7;
          //border-radius: 15px 15px 0 0;
        }
      }
    }
    &.shape-ring {
      top: 75%;
      left: 25%;
      &:after {
        content: "";
        border-radius: 50%;
        height: 20px;
        width: 20px;
        border: 5px solid $color-primary-4;
      }
    }
    &.shape-circle-1 {
      top: 30%;
      left: 75%;
      animation-duration: 15s;
      &:after {
        content: "";
        width: 15px;
        height: 15px;
        background: rgba(237, 244, 248, 0.3);
        border-radius: 50%;
        margin: 0;
      }
    }
    &.shape-circle-2 {
      top: 60%;
      left: 30%;
      & div:after {
        content: "🚔";
        width: 5px;
        height: 5px;
        //background: colorSecondary(0.3);
        //border-radius: 50%;
        margin: 0;
      }
    }
    &.shape-triangle {
      top: 50%;
      left: 50%;
      animation-duration: 12s;
      div {
        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
          width: 30px;
          height: 30px;
          border-top-right-radius: 30%;
          content: "";
          position: absolute;
          background-color: inherit;
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
          width: 30px;
          height: 30px;
          border-top-right-radius: 30%;
          content: "";
          position: absolute;
          background-color: inherit;
        }
        width: 30px;
        height: 30px;
        border-top-right-radius: 30%;
        opacity: 0.1;
        position: relative;
        background-color: $color-primary-4;
        text-align: left;
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
      }
    }
    &.shape-diamond {
      top: 35%;
      left: 55%;
      div {
        opacity: 0.5;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: $color-secondary;
        position: relative;
        top: -10px;
        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          left: -10px;
          top: 10px;
          border: 10px solid transparent;
          border-top-color: $color-secondary;
        }
      }
    }
  }
}
#features {
  position: relative;
  z-index: 13;
  .container {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 2.875rem;
    &:after {
      content: "";
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
      width: 40px;
      height: 2px;
      background-color: $color-primary;
      margin-left: auto;
      margin-right: auto;
    }
  }
  h5 {
    font-size: 1.25rem;
    font-width: 700;
  }
  ion-icon {
    color: $primary;
    font-size: 3em;
  }
  p {
    margin: 1rem 0;
  }
}
.built-for {
  z-index: 10;
  position: relative;
  padding-bottom: 90px;
  padding-top: 90px;
  .shape {
    position: absolute;
  }
  .shapes-container {
    overflow: unset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .shape-circle-1 {
      left: 7%;
      top: 60%;
      div {
        width: 150px;
        height: 150px;
        background: #007cb7;
        border-radius: 50%;
        margin: 0;
      }
    }
    .shape-circle-2 {
      left: 60%;
      top: 70%;
      div {
        width: 120px;
        height: 120px;
        background: rgba(0, 124, 183, 0.8);
        border-radius: 50%;
        margin: 0;
      }
    }
    .shape-circle-3 {
      left: 40%;
      top: 78%;
      div {
        width: 120px;
        height: 120px;
        background: #007cb7;
        border-radius: 50%;
        margin: 0;
      }
    }
    .shape-circle-4 {
      left: 25%;
      top: 70%;
      div {
        width: 50px;
        height: 50px;
        background: $color-primary-1;
        border-radius: 50%;
        margin: 0;
      }
    }
    .shape-triangle {
      top: 80%;
      right: 0;
      div {
        width: 95px;
        height: 95px;
        border-top-right-radius: 30%;
        opacity: 0.15;
        position: relative;
        background: $primary
          linear-gradient(45deg, $primary 0, $color-primary-1 100%);
        text-align: left;
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
        animation-duration: 50s;
        &:before,
        &:after {
          width: 95px;
          height: 95px;
          border-top-right-radius: 30%;
          content: "";
          position: absolute;
          background: inherit;
        }
        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
        }
      }
    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 2.875rem;
    &:after {
      content: "";
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
      width: 40px;
      height: 2px;
      background-color: $color-primary;
    }
  }
}
#for-dispatchers {
  position: relative;
  .shape {
    position: absolute;
  }
  .shapes-container {
    overflow: unset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .shape-circle {
      bottom: 0;
      right: 0;
    }
    .shape-ring {
      left: 50%;
      top: 0;
      div {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        border: 5px solid $color-secondary-1-1;
      }
    }
    .pattern-dots-1 {
      bottom: 0;
      right: 0;
      width: 150px;
      height: 50%;
      -webkit-transform: translate3d(0, 50%, 0);
      transform: translate3d(0, 50%, 0);
      background-color: transparent;
      background-image: radial-gradient(
        rgba(39, 51, 62, 0.3) 3px,
        transparent 3px
      );
      background-size: 30px 30px;
    }
    .shape-triangle {
      top: 80%;
      left: 0;
      div {
        width: 95px;
        height: 95px;
        border-top-right-radius: 30%;
        opacity: 0.15;
        position: relative;
        background-color: $color-primary;
        background: linear-gradient(
          45deg,
          $color-primary 0,
          $color-primary-1 100%
        );
        text-align: left;
        -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
        transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
        -webkit-animation-duration: 50s;
        animation-duration: 50s;
        &:before {
          transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
          width: 95px;
          height: 95px;
          border-top-right-radius: 30%;
          content: "";
          position: absolute;
          background: inherit;
        }
        &:after {
          transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
          width: 95px;
          height: 95px;
          border-top-right-radius: 30%;
          content: "";
          position: absolute;
          background: inherit;
        }
      }
    }
    .shape-circle-1 {
      transform: translate3d(25%, 10%, 0);
      div {
        width: 750px;
        height: 750px;
        background: rgba(237, 244, 248, 0.5);
        border-radius: 50%;
        margin: 0;
      }
    }
    .shape-circle-2 {
      transform: translate3d(25%, 10%, 0);
      div {
        width: 500px;
        height: 500px;
        background: rgba(237, 244, 248, 0.6);
        border-radius: 50%;
        margin: 0;
      }
    }
  }
  .map-marker {
    position: absolute;
    width: 65px;
    left: 30%;
    top: 95px;
  }
}
#for-towers {
  .shape-circle {
    div {
      opacity: 0.4 !important;
    }
  }
  .tower-wrapper {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 1.5rem;
    }
    .green {
      color: green;
    }
    .yellow {
      color: orange;
    }
  }
}
#faq {
  background-color: #edf4f8 !important;
  position: relative;
  z-index: 9;
  padding-top: 90px;
  padding-bottom: 90px;
  &:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(237,244,248)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px;
  }
  .shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    .card-header {
      border-radius: 0.25rem;
      background-color: #fff;
      border-bottom: 0;
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      .card-title {
        color: $color-primary !important;
      }
    }
    .card-body {
      padding: 1rem 1.25rem 1.25rem 2rem;
      flex: 1 1 auto;
    }
  }
  [data-toggle="collapse"] {
    display: flex;
    &:after {
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      font-weight: 900;
      content: "\f107";
      transition: transform 0.3s ease;
      margin-left: auto;
    }
    &.collapsed:after {
      transform: rotate(-90deg);
    }
  }
  .icon {
    position: absolute;
    left: 0;
    top: 30%;
    font-size: 26rem;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1;
    margin-left: 5px;
    margin-right: 15px;
    padding: 0;
    display: inline-block;
    text-align: center;
    .fa-question {
      &:before {
        content: "\f128";
      }
      margin: 0;
      transform: translate3d(-25%, 0, 0);
      font-size: 26rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
#contact {
  z-index: 9 !important;
  position: relative;
  .container {
    padding-bottom: 90px !important;
    padding-top: 90px !important;
  }
  h2 {
  }
  p {
    &:after {
      content: "";
      display: block;
      margin-top: 32px;
      margin-bottom: 24px;
      width: 40px;
      height: 2px;
      background-color: $color-primary;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
footer {
  position: relative;
  display: block;
  a {
    color: $color-white;
    &:hover {
      color: $gray-400 !important;
    }
  }
  .container:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(24,23,21)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    height: 310px;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
    top: -310px;
  }
  color: $color-white;
  background-color: #181715;
  .logo {
    width: 100%;
    max-width: 75px;
  }
  hr {
    border-top: 1px solid #edf4f8;
  }
  p {
    margin: 1rem 0;
  }
  .row.gap-y > [class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .btn-circle {
    display: inline-flex;
    padding: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    line-height: 1.5em;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
  }
  .brand-linkedin {
    color: #fff;
    background-color: #0072b1;
    border-color: #0072b1;
    i {
      padding-bottom: 5px;
    }
  }
  .wob-logo {
    max-width: 100px;
  }
}
// END SECTION SCOPED STYLES

// START MEDIA QUERIES
@media (min-width: 768px) {
  .hero {
    h1 {
      font-size: 5.5rem !important;
    }
  }
  #main-nav {
    background-color: transparent;
    .navbar-toggler {
      display: none;
    }
    .sidebar-brand {
      display: none;
    }
    .navbar-collapsable {
      position: relative;
      width: auto;
      height: auto;
      background: 0 0;
      display: flex !important;
      flex-basis: auto;
      left: 0;
      border-right: none;
    }
    .navigation-list {
      justify-content: center;
      flex-direction: row !important;
    }
    .navigation-link {
      padding-left: 1.25rem !important;
      padding-right: 1.25rem !important;
      color: $color-white !important;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    }
  }
  .hero-shapes-container {
    // START - override opacities to be stronger for desktop
    .shape-animated,
    .shape-in-motion,
    .static-shape {
      opacity: 1 !important;
    }
    .shape-animated:nth-child(n + 5):before {
      opacity: 0.5;
    }
    .static-shape {
      &.circle-1,
      &.circle-2 {
        opacity: 0.2 !important;
      }
      &.background-shape {
        height: 115%;
        transform: translate(35%, -30%) rotate(-35deg);
      }
    }
    // END - override opacities to be stronger for desktop

    .shape-animated {
      &:nth-child(1) {
        top: 25%;
      }
      &:nth-child(2) {
        top: 20%;
      }
    }
  }
  .built-for .shapes-container {
    .shape-circle-1 {
      left: 25%;
      top: 5%;
    }
    .shape-circle-2 {
      left: 22%;
      top: 23%;
    }
  }
}
@media (min-width: 992px) {
  .hero-shapes-container {
    .static-shape {
      &.background-shape {
        height: 130%;
      }
    }
    .shape-animated {
      &:nth-child(1) {
        left: 12%;
      }
      &:nth-child(2) {
        top: 10%;
        width: 300px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .hero-shapes-container {
    .static-shape {
      &.background-shape {
        height: 190%;
      }
    }
    .shape-animated {
      &:nth-child(1) {
        left: 31%;
      }
      &:nth-child(2) {
        left: 31%;
      }
    }
  }
}
// END MEDIA QUERIES

// START KEYFRAMES
@keyframes clockwise {
  0% {
    transform: rotate(0) translate(-165px) rotate(0);
  }
  100% {
    transform: rotate(360deg) translate(-165px) rotate(-360deg);
  }
}
@keyframes anti-clockwise {
  0% {
    transform: rotate(0) translate(165px) rotate(0);
  }
  100% {
    transform: rotate(-360deg) translate(165px) rotate(360deg);
  }
}
@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotating-diagonal {
  0% {
    transform: translate(-300px, 150px) rotate(0);
  }
  100% {
    transform: translate(300px, -150px) rotate(180deg);
  }
}
// END KEYFRAMES
